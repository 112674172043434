import { getStartOfMonth, floatToDate, dateToInteger } from '@/parse-and-play/utils.js'
import Transaction from '@/structs/transaction.js'
import Posting from '@/parse-and-play/posting.js'

class Periodic {
  constructor (attrs) {
    this.id = attrs.id
    this.identityId = attrs.identityId
    this.name = attrs.name
    this.frequency = attrs.frequency.toString().split(/\s/)[0].toLowerCase()
    this.startAt = attrs.startAt
    this.endAt = attrs.endAt
    this.referenceId = attrs.referenceId
    this.comments = attrs.comments || []
    this.postings = attrs.postings || []
  }

  transactionsFor (startAt, endAt) {
    if (this.frequency === Periodic.FREQUENCIES.MONTH) {
      return this._monthlyTransactionsFor(startAt, endAt)
    } else if (this.frequency === Periodic.FREQUENCIES.ANNUAL) {
      return this._annualTransactionsFor(startAt, endAt)
    }
  }

  _monthlyTransactionsFor (startAt, endAt) {
    const transactions = []
    const periodicEndAt = this.endAt ? floatToDate(this.endAt) : null
    const periodicStartAt = this.startAt ? floatToDate(this.startAt) : null

    if (periodicEndAt && startAt.getTime() > periodicEndAt.getTime()) {
      return transactions
    }

    if (periodicStartAt && endAt.getTime() < periodicStartAt.getTime()) {
      return transactions
    }

    if (periodicStartAt && startAt.getTime() < periodicStartAt.getTime()) {
      startAt = periodicStartAt
    }

    if (periodicEndAt && endAt.getTime() > periodicEndAt.getTime()) {
      endAt = periodicEndAt
    }

    const startOfNowMonth = getStartOfMonth(new Date())
    if (startAt.getTime() < startOfNowMonth.getTime()) {
      startOfNowMonth.setMonth(startOfNowMonth.getMonth() + 1)
      startAt = startOfNowMonth
    }

    let date = new Date(startAt)

    while (date.getTime() < endAt.getTime()) {
      transactions.push(new Transaction({
        date: dateToInteger(date),
        periodicName: this.name,
        comments: this.comments,
        referenceId: this.referenceId,
        payee: null,
        status: '!',
        postings: this.postings.map((posting) => (new Posting(
          posting.amount,
          posting.currency,
          posting.account,
          posting.elided,
          posting.value,
          posting.comment
        )))
      }))

      date = new Date(date)
      date.setMonth(date.getMonth() + 1)
    }

    return transactions
  }

  _annualTransactionsFor (startAt, endAt) {
    return []
  }
}

Periodic.FREQUENCIES = {
  MONTH: 'monthly',
  ANNUAL: 'yearly'
}

export default Periodic
