<template>
  <div>
    <div
      v-if="!transactions.length"
      class="text-center whitespace-nowrap p-8"
    >
      <p class="grid h-32 place-items-center">
        No transactions found.
      </p>
    </div>
    <div v-else>
      <SankeyChart
        :transactions="transactions"
      />
    </div>
  </div>
</template>

<script>
import SankeyChart from '@/app/views/transactions/charts/sankey-chart.vue'

export default {
  components: {
    SankeyChart
  },
  props: ['app', 'sheet', 'transactions']
}
</script>
